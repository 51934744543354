<template>
  <v-sheet class="overflow-hidden border-radius">
    <v-progress-linear
      :color="search.loading ? 'primary' : 'transparent'"
      :indeterminate="search.loading"
      height="2"
    />
    <v-data-table
      :value="selection"
      @click:row="clickRow($event)"
      @item-selected="selectItem"
      @toggle-select-all="selectAllItems($event.value)"
      @update:options="handleOptions"
      :class="{'multi-sorted': isMultiEnabled, 'search-selectable-rows': showSelect, 'no-results':  !search.items.length}"
      :headers="headers"
      :hide-default-header="!search.items.length && !search.selectedCount"
      :item-class="applyItemClass"
      :items="search.items"
      :multi-sort="isMultiEnabled"
      :options="dataTableOptions"
      :show-select="showSelect"
      checkbox-color="primary"
      disable-filtering
      disable-pagination
      fixed-header
      hide-default-footer
      item-key="key"
    >
      <!-- Table header overrides -->
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:header.data-table-select="{ on }">
        <div class="d-flex">
          <v-simple-checkbox
            v-on="on"
            color="primary"
            :indeterminate="indeterminateState"
            :ripple="false"
            :value="search.allItemsSelected || !search.selectedItems.isEmpty()"
          />
          <bulk-actions :search="search" />
        </div>
      </template>

      <template
        v-for="(h, index) in search.columns"
        v-slot:[`header.${h.value}`]="{header}"
      >
        <div
          :data-cy="'header-'+index"
          :key="h.id"
          class="header-wrapper align-center"
        >

          <span>{{header.text}}</span>
          <pinned-btn
            v-if="pinableColumnsConfiguration[header.type] && !search.filterSet.hasConditionForColumn(header.id, header.currency, header.consolidated)"
            class="hover-button"
            small
            @click.stop="pinCondition(header)"
          />
        </div>
      </template>

      <!-- Table data overrides -->
      <!-- Override the default select cell-->
      <template v-slot:[`item.data-table-select`]="{ isSelected, select, index }">
        <!-- We use this slot with this div to hide the background behind the select column (problematic because of the stickiness) -->
        <div
          class="selection-wrapper d-flex align-center justify-center"
          @click.stop="select(!isSelected)"
        >
          <v-simple-checkbox
            :data-cy="'row-'+index+'-checkbox'"
            :ripple="false"
            :value="isSelected"
            @input="select($event)"
            color="primary"
            class="pr-5"
          />
        </div>
      </template>

      <template
        v-for="(col, colIndex) in search.columns"
        v-slot:[`item.${col.value}`]="{ item, index }"
      >
        <div
          :data-cy="'row-'+index+'-col-'+colIndex"
          class="sticky-column-wrapper d-flex align-center"
          :key="col.id"
        >
          <column-mapper
            class="flex-grow-1"
            :account-id="item.accountId"
            :column="col"
            :item-id="item.id"
            :extra="item.extra"
            :value="item[col.value]"
            :scope-modes="scopeModes"
          />
        </div>
      </template>

      <template
        v-if="displayQuickActions"
        v-slot:[`item.quickActions`]="{ item, index }"
      >
        <!-- We use this div to hide the background behind the quick-actions column (problematic because of the stickiness) -->
        <div class="quick-actions-wrapper d-flex align-center justify-center">
          <quick-actions
            :data-cy="'row-'+index+'-quick-action'"
            @click="setActiveRow(item.id)"
            :item="item"
            :search="search"
          />
        </div>
      </template>

      <!-- Table footer overrides -->
      <template v-slot:foot>
        <tfoot
          v-if="search.items.length"
          class="tfoot background-plain font-weight-bold"
        >
          <tr class="background-translucid">
            <td
              v-for="(footerColumn, idx) in footer"
              :key="idx + 'footer'"
              :class="{
                [footerColumn.cellClass]: footerColumn.cellClass,
                [`text-${footerColumn.align}`]: footerColumn.align,
                'pl-5': showSelect && idx === 0}"
              class="footer-height border-top"
            >
              <div class="d-flex flex-column justify-space-around footer-height">
                <template v-if="!search.selectedItems.isEmpty()">
                  <footer-summary
                    :footerColumn="footerColumn"
                    selectedSummary
                  />
                  <span
                    :class="[idx === 0 ? 'ml-n5': 'mx-n4']"
                    class="d-flex dash"
                  />
                </template>
                <footer-summary
                  :footerColumn="footerColumn"
                  :selectedSummary="idx == 0 && search.selectedItems.isEmpty()"
                  class="my-1"
                />
              </div>
            </td>
          </tr>
        </tfoot>
      </template>

      <!-- Table no-data overrides -->
      <template v-slot:no-data>
        <v-scroll-x-transition hide-on-leave>
          <div
            data-cy="search-no-data"
            class="d-flex flex-column flex-nowrap align-center py-8"
            v-if="isInitialized"
          >
            <div
              class="d-flex flex-shrink-1 my-8"
              style="overflow: hidden; max-height: 50%"
            >
              <v-img
                contain
                src="@/assets/no-results.svg"
              />
            </div>
            <div class="text-h3 py-4">
              {{$t("t.NoResult")}}
            </div>
            <div class="d-flex align-center">
              <v-icon>{{$icon('i.Search')}}</v-icon>
              <div class="my-2 ml-2">{{$t("t.NoOccurrencesFor")}} <span class="primary--text font-weight-medium">{{search.searchText}}</span>. {{$t("t.NoData")}}</div>
            </div>
          </div>
        </v-scroll-x-transition>
      </template>
    </v-data-table>
  </v-sheet>
</template>
<script>
import Column from '../controllers/column'
import Scope from '../controllers/scope'
import Search from '../controllers'
import Navigation from '@/navigationController'

export default {
  components: {
    BulkActions: () => import('@/pages/search/components/actions/bulk-actions'),
    ColumnMapper: () => import('./column-mapper'),
    FooterSummary: () => import('./footer-summary'),
    PinnedBtn: () => import('@/pages/search/components/filter/pinned-btn'),
    QuickActions: () => import('@/pages/search/components/actions/quick-actions')
  },
  activated () {
    this.listenShiftKey()
  },
  beforeDestroy () {
    this.search.offComplete(this.setElements)
  },
  created () {
    this.search.onceComplete(() => { this.isInitialized = true })
    this.search.onComplete(this.setElements)
  },
  computed: {
    dataTableOptions () {
      return this.search.sorts
    },
    displayQuickActions () {
      return this.search.items.some(i => i.hasQuickActions)
    },
    footer () {
      const columns = [...this.search.columns]

      if (this.showSelect) {
        columns.unshift(
          {
            type: 'number-column',
            summary: undefined,
            selectedSummary: this.search.selectedCount > 9999 ? '+9999' : (this.search.selectedCount || undefined),
            align: 'left'
          }
        )
      }

      if (this.displayQuickActions) {
        columns.push({ cellClass: ['sticky-column-right'], type: 'text' })
      }

      return columns
    },
    headers () {
      if (this.displayQuickActions) {
        return this.search.columns.concat(new Column(
          {
            id: null,
            name: '',
            summary: null,
            valueType: { button: null }
          },
          null,
          'quickActions',
          {
            class: ['sticky-column-right'],
            cellClass: ['sticky-column-right'],
            sortable: false
          }
        ))
      }

      return this.search.columns
    },
    indeterminateState () {
      return !this.search.selectedItems.isEmpty() && this.search.selectedItems.size.total < this.search.resultCount
    },
    isMultiEnabled () {
      return this.isShiftPressed || this.search.sorts.sortBy.length > 1
    },
    nonClickableDocumenTypes () {
      return ['reminder-runs', 'user-authentication-logs']
    },
    pinableColumnsConfiguration () {
      return {
        'amt-column': { op: 'bw', ty: 'number' },
        'date-column': { op: 'bw', ty: 'date' },
        'id-number-column': { op: 'bw', ty: 'number' },
        'number-column': { op: 'bw', ty: 'number' },
        'text-column': { op: 'c', ty: 'string' }
      }
    },
    selection () {
      return this.search.selectionModeExclusive ? this.lodash.differenceBy(this.search.items, this.search.selectedItems.excludeItems, 'id') : this.search.selectedItems.includeItems
    },
    showSelect () {
      return !!this.search.canSelectItems
    }
  },
  data () {
    return {
      activeRow: undefined, // Active row is used to apply z-index and allow quick-actions menus to properly pop-over
      elements: { boundingArea: undefined, footer: undefined },
      isInitialized: false,
      isShiftPressed: false,
      lastClickedItem: { item: undefined, value: true },
      lastScrollx: undefined
    }
  },
  deactivated () {
    this.stopListenShiftKey()
  },
  destroyed () {
    this.stopListenShiftKey()
  },
  mounted () {
    this.listenShiftKey()
  },
  methods: {
    async selectAllItems (value) {
      return await this.search.selectAllItems(value)
    },
    applyItemClass (item) {
      let auditClass
      // Using a switch because item.success can be undefined and should not add a class in this case
      switch (item.success) {
        case true: auditClass = 'audit-match'
          break
        case false: auditClass = 'audit-mismatch'
      }
      return [auditClass, item.id === this.activeRow ? 'active-row' : null].filter(c => c).join(' ')
    },
    clickRow (document) {
      if (window.document.getSelection().toString().length) { return }
      if (this.nonClickableDocumenTypes.includes(this.search.documentType)) { return }

      if (this.search.linkTarget) {
        return this.$emit('row-action', document)
      }

      if (
        this.search.scope.type === Scope.DISPUTE_INVOICES_ACCOUNT ||
        this.search.scope.type === Scope.PROMISE_INVOICES_ACCOUNT) {
        return this.$emit('row-action', { action: 'addOrRemove', invoice: document })
      }

      let params

      if (['column-sets', 'sort-sets'].includes(this.search.documentType)) {
        params = { targetDocTy: document.extra.type, scope: document.extra.scope }
      }
      Navigation.navigateTo(document.id, document.type, params)
    },
    eventListener (event) {
      if (event.key === 'Shift') {
        this.isShiftPressed = event.type === 'keydown'
        this.stopListenShiftKey()
        if (event.type === 'keydown') {
          this.listenShiftKeyUp()
        } else {
          this.listenShiftDown()
        }
      }
    },
    handleOptions (options) {
      if (
        this.lodash.isEqual(this.search.sorts.sortBy, options.sortBy) &&
        this.lodash.isEqual(this.search.sorts.sortDesc, options.sortDesc)
      ) { return }

      let { sortBy, sortDesc } = options
      if (!this.isShiftPressed) {
        const changed = this.lodash.take(this.lodash.xor(sortBy, this.search.sorts.sortBy))
        if (changed.length) {
          const isColRemoved = sortBy.length < this.search.sorts.sortBy.length
          sortBy = isColRemoved ? [] : changed
          sortDesc = isColRemoved ? [] : [false]
        } else {
          const changedIndex = this.lodash.findIndex(sortDesc, (o, i) => o !== this.search.sorts.sortDesc[i])
          sortBy = [sortBy[changedIndex]]
          sortDesc = sortDesc.length > 1 ? [false] : [sortDesc[changedIndex]]
        }
      }

      this.search.setSortSet({ sortBy, sortDesc }, true).execute()
    },
    listenShiftKey () {
      this.stopListenShiftKey()
      this.listenShiftDown()
    },
    listenShiftDown () {
      this.lastClickedItem.item = undefined
      this.lastClickedItem.value = true
      document.addEventListener('keydown', this.eventListener)
    },
    listenShiftKeyUp () {
      document.addEventListener('keyup', this.eventListener)
    },
    async selectItem ({ item, value }) {
      if (!item) { return }
      if (!this.isShiftPressed || !this.lastClickedItem.item) {
        this.lastClickedItem.item = item
        this.lastClickedItem.value = value
      }
      if (this.isShiftPressed) {
        const lastIndex = this.search.items.indexOf(this.lastClickedItem.item)
        const newIndex = this.search.items.indexOf(item)
        const start = Math.min(lastIndex, newIndex)
        const end = Math.max(lastIndex, newIndex)
        for (let i = start; i <= end; i++) {
          this.search.setItemSelection(this.search.items[i], this.lastClickedItem.value)
        }
      } else {
        this.search.setItemSelection(item, value)
      }
    },
    setActiveRow (id) {
      this.activeRow = id
    },
    async setElements () {
      await this.$waitFor(() => this.$el?.querySelector('.v-data-table__wrapper') && (this.search.items.length ? this.$el?.querySelector('tfoot') : true))
      this.$set(this.elements, 'boundingArea', this.$el?.querySelector('.v-data-table__wrapper'))
      this.$set(this.elements, 'footer', this.$el?.querySelector('tfoot'))
    },
    stopListenShiftKey () {
      document.removeEventListener('keydown', this.eventListener)
      document.removeEventListener('keyup', this.eventListener)
    },
    pinCondition (column) {
      this.search.pinCondition(
        column,
        this.pinableColumnsConfiguration[column.type]?.op,
        this.pinableColumnsConfiguration[column.type]?.ty
      )
    }
  },
  provide () {
    return {
      elements: this.elements
    }
  },
  watch: {
    async 'search.items' (items) {
      if (this.search.page > 0 && this.search.items.length === 0 && this.search.resultCount > 0) {
        this.search.setPage(1)
        await this.search.execute()
      } else {
        const item = items.find(i => i.id === this.lastClickedItem.item?.id && i.type === this.lastClickedItem.item?.type)
        if (item) {
          this.lastClickedItem.item = item
        } else {
          this.lastClickedItem.item = undefined
          this.lastClickedItem.value = true
        }
      }

      if (!this.search.items.length) {
        await this.$waitFor(() => this.elements.boundingArea)
        this.lastScrollx = this.elements.boundingArea.scrollLeft
        this.elements.boundingArea.scroll(this.elements.boundingArea.firstChild.getBoundingClientRect().width / 8, 0)
      } else if (typeof this.lastScrollx !== 'undefined') {
        this.elements.boundingArea.scroll(this.lastScrollx, 0)
        this.lastScrollx = undefined
      }
    }
  },
  props: {
    search: {
      required: true,
      type: Search
    },
    scopeModes: Array
  }
}
</script>

<style lang="stylus" scoped>
.theme--dark.v-data-table .border-top
  border-top thin solid rgba(255, 255, 255, 50%) !important

.theme--light.v-data-table .border-top
  border-top thin solid rgba(0, 0, 0, 50%) !important

.footer-height
  min-height 48px
  height 100% !important

.dash
  opacity 0.12 !important
  border-bottom solid 1px !important

>>>
  table
    background-color var(--bg-transparency) !important
    // Prevent displaying a horizontal scrollbar if the columns are shrinked to fit the screen width
    width calc(100% - 2px) !important

  th
    min-width 90px
    white-space nowrap

  .header-wrapper>span
    white-space normal
    height auto
    line-height 1

  .header-wrapper+i
    margin-left -2px

  // Fix sort arrows display
  .header-wrapper
    display inline-grid
    grid-template 1fr / auto auto
    margin-right 2px
    vertical-align inherit

  div tbody tr
    cursor pointer

  .v-data-table, .v-data-table__wrapper
    height 100%

  .v-data-table__wrapper
    overscroll-behavior none
    // Prevent displaying a horizontal scrollbar if the columns are shrinked to fit the screen width
    width calc(100% + 2px) !important

  .mdi-minus-box
    color var(--primary-color) !important

  .audit-match
    color #4caf50

    & .v-icon
      color #4caf50

  .audit-mismatch
    color #f44336

    & .v-icon
      color #f44336

// US 8360 - FIX UI adhérence - search list
.theme--light >>> thead tr, .theme--light >>> th, .theme--light >>> th :not(i[class*='box'], i[class*='chevron']):not(span, div, button, i)
  color initial !important
  background-color #FFFFFF !important

.theme--light >>> .v-data-table thead th
  box-shadow inset 0 -2px 0 rgba(0, 0, 0, 0.12) !important

>>> th .v-data-table-header__sort-badge
  background-color transparent !important

.tfoot
  position sticky
  bottom 0

// END OF US 8360
.multi-sorted >>> .header-wrapper
  max-width calc(100% - 20px)

>>> .sticky-column-left
  position sticky
  left 6em
  z-index 4 !important

>>> thead .sticky-column-left, .search-selectable-rows >>> thead th:first-child, >>> thead .sticky-column-right
  z-index 5 !important

// RIGHT UNDER THIS COMMENT: STYLES USED FOR THE STICKY QUICK-ACTIONS AND SELECTIONS
// Class search-selectable-rows: to prevent the *:first-child to alter the search-select-list when rows are not selectable
>>> .sticky-column-right
  position sticky
  right 0
  padding 0 !important

>>> tr:not(.v-data-table__selected) td.sticky-column-left, >>> tr:not(.v-data-table__selected) td.sticky-column-right, .search-selectable-rows >>> tr:not(.v-data-table__selected) td:first-child
  background-color inherit

>>> tr.v-data-table__selected .sticky-column-right, .search-selectable-rows >>> tr.v-data-table__selected td:first-child
  background-color var(--bg-transparency) !important

>>> tr:not(:hover):not(.v-data-table__selected) td.sticky-column-left, >>> tr:not(:hover):not(.v-data-table__selected) td.sticky-column-right, .search-selectable-rows >>> tr:not(:hover):not(.v-data-table__selected) td:first-child
  background-color var(--bg-transparency) !important

.search-selectable-rows >>> th:first-child, .search-selectable-rows >>> td:first-child
  position sticky
  left 0
  z-index 4 !important

.search-selectable-rows >>> td:first-child, thead, .search-selectable-rows >>> td:first-child, tfoot
  padding 0
  z-index 4 !important

.search-selectable-rows .selection-wrapper, .quick-actions-wrapper
  height 100%
  width 100%

>>> tr.v-data-table__selected .quick-actions-wrapper, .search-selectable-rows >>> tr.v-data-table__selected .selection-wrapper, .search-selectable-rows >>> tr.v-data-table__selected .sticky-column-left>.sticky-column-wrapper
  background-color var(--primary-color-10)

>>> tr.active-row .sticky-column-left, >>> tr.active-row .sticky-column-right
  z-index 1

>>> .sticky-column-wrapper
  height 100%

>>> tr.v-data-table__selected .sticky-column-left
  padding 0 !important
  background-color var(--bg-transparency) !important

  &>.sticky-column-wrapper
    padding 0 16px
    background-color var(--primary-color-10)

// Hovering cell to display button
>>> .hover-button
  opacity 0

>>> th:hover .hover-button
  opacity unset

.no-results >>> &>*
  overflow hidden
</style>
