var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"overflow-hidden border-radius"},[_c('v-progress-linear',{attrs:{"color":_vm.search.loading ? 'primary' : 'transparent',"indeterminate":_vm.search.loading,"height":"2"}}),_c('v-data-table',{class:{'multi-sorted': _vm.isMultiEnabled, 'search-selectable-rows': _vm.showSelect, 'no-results':  !_vm.search.items.length},attrs:{"value":_vm.selection,"headers":_vm.headers,"hide-default-header":!_vm.search.items.length && !_vm.search.selectedCount,"item-class":_vm.applyItemClass,"items":_vm.search.items,"multi-sort":_vm.isMultiEnabled,"options":_vm.dataTableOptions,"show-select":_vm.showSelect,"checkbox-color":"primary","disable-filtering":"","disable-pagination":"","fixed-header":"","hide-default-footer":"","item-key":"key"},on:{"click:row":function($event){return _vm.clickRow($event)},"item-selected":_vm.selectItem,"toggle-select-all":function($event){return _vm.selectAllItems($event.value)},"update:options":_vm.handleOptions},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-flex"},[_c('v-simple-checkbox',_vm._g({attrs:{"color":"primary","indeterminate":_vm.indeterminateState,"ripple":false,"value":_vm.search.allItemsSelected || !_vm.search.selectedItems.isEmpty()}},on)),_c('bulk-actions',{attrs:{"search":_vm.search}})],1)]}},_vm._l((_vm.search.columns),function(h,index){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('div',{key:h.id,staticClass:"header-wrapper align-center",attrs:{"data-cy":'header-'+index}},[_c('span',[_vm._v(_vm._s(header.text))]),(_vm.pinableColumnsConfiguration[header.type] && !_vm.search.filterSet.hasConditionForColumn(header.id, header.currency, header.consolidated))?_c('pinned-btn',{staticClass:"hover-button",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.pinCondition(header)}}}):_vm._e()],1)]}}}),{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var index = ref.index;
return [_c('div',{staticClass:"selection-wrapper d-flex align-center justify-center",on:{"click":function($event){$event.stopPropagation();return select(!isSelected)}}},[_c('v-simple-checkbox',{staticClass:"pr-5",attrs:{"data-cy":'row-'+index+'-checkbox',"ripple":false,"value":isSelected,"color":"primary"},on:{"input":function($event){return select($event)}}})],1)]}},_vm._l((_vm.search.columns),function(col,colIndex){return {key:("item." + (col.value)),fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{key:col.id,staticClass:"sticky-column-wrapper d-flex align-center",attrs:{"data-cy":'row-'+index+'-col-'+colIndex}},[_c('column-mapper',{staticClass:"flex-grow-1",attrs:{"account-id":item.accountId,"column":col,"item-id":item.id,"extra":item.extra,"value":item[col.value],"scope-modes":_vm.scopeModes}})],1)]}}}),(_vm.displayQuickActions)?{key:"item.quickActions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"quick-actions-wrapper d-flex align-center justify-center"},[_c('quick-actions',{attrs:{"data-cy":'row-'+index+'-quick-action',"item":item,"search":_vm.search},on:{"click":function($event){return _vm.setActiveRow(item.id)}}})],1)]}}:null,{key:"foot",fn:function(){return [(_vm.search.items.length)?_c('tfoot',{staticClass:"tfoot background-plain font-weight-bold"},[_c('tr',{staticClass:"background-translucid"},_vm._l((_vm.footer),function(footerColumn,idx){
var _obj;
return _c('td',{key:idx + 'footer',staticClass:"footer-height border-top",class:( _obj = {}, _obj[footerColumn.cellClass] = footerColumn.cellClass, _obj[("text-" + (footerColumn.align))] = footerColumn.align, _obj['pl-5'] =  _vm.showSelect && idx === 0, _obj )},[_c('div',{staticClass:"d-flex flex-column justify-space-around footer-height"},[(!_vm.search.selectedItems.isEmpty())?[_c('footer-summary',{attrs:{"footerColumn":footerColumn,"selectedSummary":""}}),_c('span',{staticClass:"d-flex dash",class:[idx === 0 ? 'ml-n5': 'mx-n4']})]:_vm._e(),_c('footer-summary',{staticClass:"my-1",attrs:{"footerColumn":footerColumn,"selectedSummary":idx == 0 && _vm.search.selectedItems.isEmpty()}})],2)])}),0)]):_vm._e()]},proxy:true},{key:"no-data",fn:function(){return [_c('v-scroll-x-transition',{attrs:{"hide-on-leave":""}},[(_vm.isInitialized)?_c('div',{staticClass:"d-flex flex-column flex-nowrap align-center py-8",attrs:{"data-cy":"search-no-data"}},[_c('div',{staticClass:"d-flex flex-shrink-1 my-8",staticStyle:{"overflow":"hidden","max-height":"50%"}},[_c('v-img',{attrs:{"contain":"","src":require("@/assets/no-results.svg")}})],1),_c('div',{staticClass:"text-h3 py-4"},[_vm._v(" "+_vm._s(_vm.$t("t.NoResult"))+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',[_vm._v(_vm._s(_vm.$icon('i.Search')))]),_c('div',{staticClass:"my-2 ml-2"},[_vm._v(_vm._s(_vm.$t("t.NoOccurrencesFor"))+" "),_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v(_vm._s(_vm.search.searchText))]),_vm._v(". "+_vm._s(_vm.$t("t.NoData")))])],1)]):_vm._e()])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }